import { defineStore } from 'pinia'
import { api } from '../helpers/api'
import router from '../router'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    token: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    response: null
  }),
  actions: {
    async login(credentials) {
      this.loading = true
      this.clearResponseAndError()
      try {
        const response = await api.post('/auth/login/user', credentials)
        this.token = response.data.token
        this.user = response.data.user
        this.isAuthenticated = true

        localStorage.setItem('token', this.token)
        localStorage.setItem('user', JSON.stringify(this.user))

        router.push('/')
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao fazer login'
      } finally {
        this.loading = false
      }
    },
    async logout() {
      this.token = null
      this.user = null
      this.isAuthenticated = false

      localStorage.removeItem('token')
      localStorage.removeItem('user')

      router.push('/')
    },
    async checkAuth() {
      const token = localStorage.getItem('token')
      const user = localStorage.getItem('user')

      if (token && user) {
        this.token = token
        this.user = JSON.parse(user)
        this.isAuthenticated = true
      } else {
        this.isAuthenticated = false
      }
    },
    async forgotPassword(data) {
      this.loading = true
      this.clearResponseAndError()
      try {
        const response = await api.post('/auth/forgot-password', {
          email: data.email
        })

        this.response = response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao esquecer senha'
      } finally {
        this.loading = false
      }
    },
    async resetPassword(data) {
      this.loading = true 
      this.clearResponseAndError()
      try {
        const response = await api.post('/auth/reset-password', data)

        this.response = response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao redefinir senha'
      } finally {
        this.loading = false
      }
    },
    clearResponseAndError() {
      this.response = null
      this.error = null
    }
  },
  getters: {}
})