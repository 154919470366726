import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'

import { vMaska } from "maska/vue"
import { createPinia } from 'pinia'

const pinia = createPinia()

const app = createApp(App)
app.use(router)
app.use(pinia)
app.directive('maska', vMaska)
app.mount('#app')