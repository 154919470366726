import axios from 'axios'
import router from '../router/index'

const baseURL = process.env.VUE_APP_BASE_API_URL;

export const api = axios.create({
    baseURL: baseURL,
})

export const apiAuth = axios.create({
    baseURL: baseURL,
})


apiAuth.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {

            localStorage.removeItem('token')
            localStorage.removeItem('user')

            router.push('/login')
        }
        
        return Promise.reject(error)
    }
)

apiAuth.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);